import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import construction from './images/under-construction.jpg';

// Pages
import MainPage from "./pages/index";
import Products from "./pages/products";
import Exhibitors from "./pages/exhibitors";
import Wowpage from './pages/wow360';
import NotFoundPage from "./pages/404";
import HallOneTwo from './pages/floorplans/hall1_2';
import HallThree from './pages/floorplans/hall3';
import HallFour from './pages/floorplans/hall4';
import HallSix from './pages/floorplans/hall6';
import HallSeven from './pages/floorplans/hall7';
import HallEight from './pages/floorplans/hall8';
import HallNine from './pages/floorplans/hall9';
import axios from 'axios';


const history = createBrowserHistory();

history.listen(location => {
  const { pathname } = location;

  window.dataLayer.push({
    event: "page_view",
    url: pathname
  });
});

window.dataLayer.push({
  event: "page_view",
  url: window.location.pathname
});

function App() {
  const [maintaining, setMaintaining] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_HOST}/api/maintenance`)
      .then(res => {
        setMaintaining(res.data.maintenance);
      })
      .catch(error => {
        // this.setState({ error: true, isloaded: true, errordata: error });
      })
  }, [])

  if (maintaining) {
    return (
      <img
        src={construction}
        alt="Under Maintenance"
        className="w-100"
      />
    )
  }
  return (
    <Router
      hashType="noslash" history={history}
    >
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/exhibitors" component={Exhibitors} />
        <Route exact path="/wow360" component={Wowpage} />
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/hall-1-2" component={HallOneTwo} />
        <Route exact path="/hall-3" component={HallThree} />
        <Route exact path="/hall-4" component={HallFour} />
        <Route exact path="/hall-6" component={HallSix} />
        <Route exact path="/hall-7" component={HallSeven} />
        <Route exact path="/hall-8" component={HallEight} />
        <Route exact path="/hall-9" component={HallNine} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default App;
