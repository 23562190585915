import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Productcards from './ProductCards';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const sort = [{ label: 'A-Z', value: 'A-Z' }, { label: 'Z-A', value: 'Z-A' }]

class VrFiltersearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            vrdata: [],
            nextpage: null,
            appendLoading: false,
            isloaded: false,
            error: false,
            errordata: '',
            search: '',
            halls: [],
            defaulthall: { label: 'Overall', value: '' },
            defaultsort: { label: 'A-Z', value: 'A-Z' },
        };
    };

    searchHandle = (e) => {
        e.persist();
        this.setState({ isloaded: false, error: false });
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?is_vr=1&hall_id=` + this.state.defaulthall.value + `&sort_by=` + this.state.defaultsort.value + `&keywords=` + e.target.value)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({ vrdata: data, isloaded: true, search: e.target.value, nextpage: nextpage });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error.data });
            })
    }

    hallHandle = (options) => {
        this.setState({ isloaded: false, error: false });
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?is_vr=1&hall_id=` + options.value + `&sort_by=` + this.state.defaultsort.value + `&keywords=` + this.state.search)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({ vrdata: data, isloaded: true, defaulthall: options, nextpage: nextpage });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error });
            })
    }

    sortHandle = (options) => {
        this.setState({ isloaded: false, error: false });
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?is_vr=1&sort_by=` + options.value + `&hall_id=` + this.state.defaulthall.value + `&keywords=` + this.state.search)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({ vrdata: data, isloaded: true, defaultsort: options, nextpage: nextpage });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error });
            })
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_HOST}/api/halls`)
            .then(res => {
                const response = res.data;
                const reformattedData = response.map((tile) => {
                    return {
                        label: tile.name,
                        value: '' + tile.id,
                    };
                })
                /* reformattedData = reformattedData.filter(function( obj ) {
                    return obj.label.charAt() === '3';
                });
                const trueData = reformattedData.map(tile => {
                    return {
                        label: tile.label.substring(3),
                        value: tile.value
                    }
                }) */

                // sort reformattedData by label
                reformattedData.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({ halls: reformattedData });
            })
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?is_vr=1`)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({ vrdata: data, isloaded: true, nextpage: nextpage });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error.data });
            })
        window.addEventListener('scroll', this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }

    onScroll = (e) => {
        e.preventDefault()
        if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 1) && this.state.nextpage && !this.state.appendLoading) {
            this.setState({ error: false, appendLoading: true });
            axios.get(this.state.nextpage + `&hall_id=` + this.state.defaulthall.value + `&sort_by=` + this.state.defaultsort.value + `&keyword=` + this.state.search)
                .then(res => {
                    const data = res.data.data;
                    const nextpage = res.data.next_page_url;
                    var joined = this.state.vrdata.concat(data);
                    this.setState({ vrdata: joined, nextpage: nextpage, appendLoading: false })
                })
            //console.log(this.state.nextpage)
        }
    }

    render() {
        return (
            <div>
                <div className="py-3 border-bottom damn-sticky">
                    <div className="row filter-bar">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-7 align-self-center"><div className="filter-main-title">WOW 360 Showcase</div></div>
                                <div className="col-5 p-0 input-m">
                                    <input type="text" onChange={this.searchHandle} placeholder="Search exhibitors here" />
                                </div>
                            </div>
                            <div className="d-flex align-self-end filter-m">
                                <div className="drop-sm flex-fill mr-3">
                                    <span className="drop-title">Select Hall</span>
                                    <Dropdown options={this.state.halls} onChange={this.hallHandle} value={this.state.defaulthall} placeholder="Select" />
                                </div>
                                <div className="drop-sm flex-fill">
                                    <span className="drop-title">Sort By</span>
                                    <Dropdown options={sort} onChange={this.sortHandle} value={this.state.defaultsort} placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-auto input-d">
                            <input type="text" onChange={this.searchHandle} placeholder="Search exhibitors here" />
                        </div>
                    </div>
                </div>

                {(this.state.isloaded && !this.state.error) ?
                    <div id="listbox">
                        <Productcards products={this.state.vrdata} mode="VR" />
                    </div>

                    : (this.state.isloaded && this.state.error) ?

                        <div id="listbox" style={{ margin: "auto", paddingTop: 50 }}>No results found</div>
                        :
                        <div className="mt-5"><CircularProgress /></div>
                }
            </div>
        )
    };
};

export default VrFiltersearch;