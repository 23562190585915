import React from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Slidingpane from './SlidingPane';
import Placeholder from '../images/placeholder.png';
import Eggthemighty from './Egglogo';

class Exhcarousel extends React.Component {

  constructor(props) {
    super(props);
    this.scroller = React.createRef();
    this.state = {
      booths: [],
      error: false,
      isloaded: false,
      active: false,
      isFocused: false,
    };
  };

  getIndex(value, arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].exhibitor_id === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  rearrangeItems(items, moveFromIndex, moveToIndex) {

    const movingItem = items[moveFromIndex];
    items.splice(moveFromIndex, 1);
    items.splice(moveToIndex, 0, movingItem);

    return items;
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_HOST}/api/halls`)
      .then(res => {
        const resd = res.data;
        const result = resd.filter(obj => {
          return obj.name === this.props.hall
        })
        const hallid = "" + result[0].id
        axios.get(`${process.env.REACT_APP_API_HOST}/api/halls/` + hallid)
          .then(res => {
            const response = res.data.hall.booths;
            const norepres = response.filter((ele, ind) => ind === response.findIndex(elem => elem.exhibitor_id === ele.exhibitor_id))
            const filteredres = norepres.filter(item => item.exhibitor != null)
            var vrpriores = filteredres;
            // eslint-disable-next-line
            filteredres.map((u, i) => {
              if (u.exhibitor.is_vr === 1) {
                vrpriores = this.rearrangeItems(vrpriores, i, 0)
              }
            })
            if (this.props.selected) {
              var theindex = this.getIndex(this.props.selected, vrpriores)
              var afterbooth = this.rearrangeItems(vrpriores, theindex, 0)
              this.setState({ booths: afterbooth, isloaded: true, })
            } else {
              this.setState({ booths: vrpriores, isloaded: true, })
            }
          })
          .catch(error => {
            this.setState({ error: true, isloaded: true, errordata: error });
          })
      })
      .catch(error => {
        this.setState({ error: true, isloaded: true, errordata: error });
      })
  }

  scrollRight = () => {
    for (let i = 1; i <= 30; i++) {
      setTimeout(() => (this.scroller.current.scrollLeft += i), i * 5);
    }
  }

  scrollLeft = () => {
    for (let i = 1; i <= 30; i++) {
      setTimeout(() => (this.scroller.current.scrollLeft -= i), i * 5);
    }
  }

  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  render() {

    if (this.state.isloaded) {
      return (
        <div className="slide-wrapper exh-list">
          <div ref={this.scroller} className="d-flex flex-nowrap slidecontain py-2">
            {this.state.booths.map(tile => {
              if (this.props.selected === tile.exhibitor_id)
                return <div className="exhcar-paper exhcar-active d-flex flex-column" key={tile.id}>
                  <div className="align-self-left d-flex flex-row">
                    <div style={{ width: 80 }}>
                      {tile.exhibitor.company_logo && <img className="slide-logo-sm" src={tile.exhibitor.company_logo} alt="logo" />}
                      {!tile.exhibitor.company_logo && <img className="slide-logo-sm" src={Placeholder} alt="logo" />}
                    </div>
                    <div className="mx-3 d-flex flex-column align-self-start flex-wrap">
                      <div className='red-box'></div>
                      <div className="d-flex">
                        <div className="mr-2">
                          <div style={{ lineHeight: 1.2 }}>{tile.exhibitor.company_name}</div>
                          <div className="exh-small">HALL {tile.exhibitor.booth_number}</div>
                        </div>
                        {tile.exhibitor.is_vr === 1 && <div className="vr-btn"><Eggthemighty size='sm' /></div>}
                      </div>
                      <div className="d-flex flex-row">
                        <button
                          title={tile.exhibitor_id}
                          onClick={this.props.locBooth}
                          /* onBlur={this.props.unlocBooth}  */
                          className="my-2 exh-locate-button">
                          Locate Booth
                        </button>
                        <Slidingpane exhibitor={tile.exhibitor_id} css={"my-2 exh-view-button"} shortname={tile.exhibitor.short_name} />
                      </div>
                    </div>
                  </div>
                </div>
              return <div className="exhcar-paper d-flex flex-column" key={tile.id}>
                <div className="align-self-left d-flex flex-row">
                  <div style={{ width: 80 }}>
                    {tile.exhibitor.company_logo && <img className="slide-logo-sm" src={tile.exhibitor.company_logo} alt="logo" />}
                    {!tile.exhibitor.company_logo && <img className="slide-logo-sm" src={Placeholder} alt="logo" />}
                  </div>
                  <div className="mx-3 d-flex flex-column align-self-start flex-wrap">
                    <div className='red-box-invisible'></div>
                    <div className="d-flex">
                      <div className="mr-2">
                        <div style={{ lineHeight: 1.2 }}>{tile.exhibitor.company_name}</div>
                        <div className="exh-small">HALL {tile.exhibitor.booth_number}</div>
                      </div>
                      {tile.exhibitor.is_vr === 1 && <div className="vr-btn"><Eggthemighty size='sm' /></div>}
                    </div>
                    <div className="d-flex flex-row">
                      <button
                        title={tile.exhibitor_id}
                        onClick={this.props.locBooth}
                        /* onBlur={this.props.unlocBooth}  */
                        className="my-2 exh-locate-button">
                        Locate Booth
                      </button>
                      <Slidingpane exhibitor={tile.exhibitor_id} css={"my-2 exh-view-button"} shortname={tile.exhibitor.short_name} />
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
          <button className="left-btn" onClick={this.scrollLeft}><ChevronLeftIcon /></button>
          <button className="right-btn" onClick={this.scrollRight}><ChevronRightIcon /></button>
        </div>
      )
    } else {
      return (<CircularProgress />)
    }
  };
}

export default Exhcarousel;