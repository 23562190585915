import React from "react";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

class Hallnine extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hallid: '',
			hallname: '',
			floorplan: null,
			booths: [],
			isloaded: false,
			error: false,
			errordata: null,
		};
	};

	onClick = (event) => {
		event.preventDefault();
		//console.log(event.target.key)
	}

	componentDidMount() {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/halls`)
			.then(res => {
				const resd = res.data;
				const result = resd.filter(obj => {
					return obj.name === this.props.hall
				})
				const hallid = "" + result[0].id
				axios.get(`${process.env.REACT_APP_API_HOST}/api/halls/` + hallid)
					.then(res => {
						const response = res.data.hall;
						this.setState({
							hallid: response.id,
							hallname: response.name,
							booths: response.booths,
							floorplan: response.floorplan,
							isloaded: true,
						});
					})
					.catch(error => {
						this.setState({ error: true, isloaded: true, errordata: error });
					})
			})
			.catch(error => {
				this.setState({ error: true, isloaded: true, errordata: error });
			})
	}

	render() {

		const { props: { handleClick }, state: { booths, isloaded, error } } = this;
		let exbooths;

		if (isloaded && !error) {
			exbooths = (
				<div>
					<img src={this.state.floorplan} alt={this.state.hallname} className="floor_img" />
					{booths.map(ex => {

						var style = {}
						if (this.props.selected === ex.exhibitor_id) {
							style = {
								position: "absolute",
								width: ex.width + "%",
								height: ex.height + "%",
								left: ex.left + "%",
								top: ex.top + "%",
								transform: ex.rotation,
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								boxShadow: "0px 0px 2px 1px #FF9595",
								background: "#FF9595",
								transition: "all 0.5s"
							}
						} else {
							style = {
								position: "absolute",
								width: ex.width + "%",
								height: ex.height + "%",
								left: ex.left + "%",
								top: ex.top + "%",
								transform: ex.rotation,
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								transition: "all 0.5s"
							}
						}

						return (
							<div className="exh-name-floor" style={style} key={ex.id} name={ex.exhibitor_id} onClick={handleClick}>
								{ex.exhibitor.short_name}
							</div>
						);
					})}
				</div>
			);
		}

		return (
			<div style={{ width: "100%", position: "relative" }}>
				{!this.state.isloaded && !this.state.error && <div className="mt-5"><CircularProgress /></div>}

				{this.state.isloaded && !this.state.error &&
					exbooths
				}

				{this.state.isloaded && this.state.error &&
					<div style={{ margin: "auto", paddingTop: 50 }}>No results found</div>
				}
			</div>
		)
	}
};

export default Hallnine