import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import Placeholder from '../images/placeholder.png';
import Awaplaceholder from '../images/award-placeholder.png';
import Eggthemighty from './Egglogo';

export function Productlist(props) {
  const [show, setShow] = React.useState(false);
  const [imgindex, setImgindex] = React.useState(0);
  const [vshow, vsetShow] = React.useState(false);
  const [vidindex, setVidindex] = React.useState(0);

  const products = props.products;
  var name = props.cname;

  if (Object.keys(products).length > 0) {
    var listItems = products.map((tile, index) =>
      <div key={index} className="border-0 prodcard-sm position-relative">
        {tile.vr_url ? <button className="img-btn-sm" onClick={() => window.open(tile.vr_url, "_blank")}>
          <img className="prodcard-img-sm" src={tile.images} alt={tile.name} style={{ width: "100%", borderRadius: "5px 5px 0 0" }} onError={(e) => { e.target.onerror = null; e.target.src = Placeholder }}></img>
        </button>
          : (tile.videos.length > 0 && tile.additional_url) ? <button className="img-btn-sm" onClick={() => window.open(tile.additional_url, "_blank")}>
            <iframe title={index} className="prodcard-img-sm" style={{ pointerEvents: 'none' }}
              src={tile.videos + "?autoplay=0&showinfo=0&controls=0&autohide=1"} frameBorder="0" allowFullScreen>
            </iframe>
          </button>
            : (tile.videos.length > 0 && !tile.additional_url) ? <button className="img-btn-sm" onClick={() => { vsetShow(true); setVidindex(index); }}>
              <iframe title={index} className="prodcard-img-sm"
                src={tile.videos + "?autoplay=0&showinfo=0&controls=0&autohide=1"} frameBorder="0" allowFullScreen>
              </iframe>
            </button>
              : (tile.images.length > 0 && tile.additional_url) ? <button className="img-btn-sm" onClick={() => window.open(tile.additional_url, "_blank")}>
                <img className="prodcard-img-sm" src={tile.images} alt={tile.name}></img>
              </button>
                : (tile.images.length > 0 && !tile.additional_url) ? <button className="img-btn-sm" onClick={() => { setShow(true); setImgindex(index) }}>
                  <img className="prodcard-img-sm" src={tile.images} alt={tile.name}></img>
                </button>
                  : <button className="img-btn-sm" onClick={() => { setShow(true); setImgindex(index) }}>
                    <img className="prodcard-img-sm" src={Placeholder} alt={tile.name}></img>
                  </button>}
        <div className="card-body p-2 border-white text-left">
          <div className="d-flex">
            <div className="mr-2">
              <div className="prodcard-title">{tile.name}</div>
              <div className="prodcard-subtitle">{name}</div>
            </div>
            {tile.vr_url && <div className="vr-btn"><Eggthemighty size="sm mb-0" /></div>}
          </div>
          {props.hall && <Link to={{
            pathname: "/hall-" + props.hall.name.substr(5).replace(' & ', '-'),
            state: { exid: tile.exhibitor_id }
          }} className="visit-booth-btn-2">Locate Booth</Link>}
        </div>
      </div>
    );
    return (
      <div className="mb-5">
        <div className="d-flex flex-wrap justify-content-left">
          {listItems}
        </div>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered={true}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={products[imgindex].images} alt={products[imgindex].name} style={{ width: "100%", borderRadius: 5 }} />
            <div className="font-weight-bold py-3" style={{ color: "#FFF", fontSize: 18 }}>{products[imgindex].name}</div>
            {props.hall && <Link to={{
              pathname: "/hall-" + props.hall.name.substr(5).replace(' & ', '-'),
              state: { exid: products[vidindex].exhibitor_id }
            }} style={{ color: "#FFF", fontSize: 10 }}>Visit Booth</Link>}
          </Modal.Body>
        </Modal>
        <Modal
          show={vshow}
          onHide={() => vsetShow(false)}
          dialogClassName="modal-100w"
          aria-labelledby="example-custom-modal-styling-title"
          centered={true}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <iframe title={vidindex} className="desktop-modal-vid" style={{ width: "100%", borderRadius: 5, minHeight: 300 }}
              src={products[vidindex].videos} frameBorder="0" allowFullScreen>
            </iframe>
            <div className="font-weight-bold py-3" style={{ color: "#FFF", fontSize: 18 }}>{products[vidindex].name}</div>
            {props.hall && <Link to={{
              pathname: "/hall-" + props.hall.name.substr(5).replace(' & ', '-'),
              state: { exid: products[vidindex].exhibitor_id }
            }} style={{ color: "#FFF", fontSize: 10 }}>Visit Booth</Link>}
          </Modal.Body>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="py-4 px-3 mb-5 slide-btm-div">
        <div className="mb-3 text-center" style={{ color: "#575757" }}>No product found.</div>
      </div>
    )
  }
}

export function Productcategory(props) {
  var categories = props.cateprops;
  var excat = [];
  categories.map((tile) => (
    excat.push(tile.name)
  ))
  var uniqEs6 = (arrArg) => arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  var uniqueSet = uniqEs6(excat)

  var listCats = uniqueSet.map((tile, index) =>
    <div key={index} className="category-card">• {tile}</div>
  );
  return (
    <div className="d-flex flex-wrap mb-3">
      {listCats}
    </div>
  )
}

export function Awardslist(props) {
  const [ishow, isetShow] = React.useState(false);
  const [awaindex, setAwaindex] = React.useState(0);

  const awards = props.awards;
  var cname = props.cname;

  var listAwards = awards.map((tile, index) =>
    <button className="award-btn" onClick={() => { isetShow(true); setAwaindex(index) }} key={index}>
      {tile.icon && <img className="award-img border" src={tile.icon} alt={tile.name}></img>}
      {!tile.icon && <img className="award-img border" src={Awaplaceholder} alt={tile.name}></img>}
    </button>
  )

  return (
    <div className="mb-5">
      <div className="d-flex flex-wrap mb-4">
        {listAwards}
      </div>
      <Modal
        show={ishow}
        onHide={() => isetShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={awards[awaindex].image} alt={awards[awaindex].name} style={{ width: "100%", borderRadius: 5 }} />
          <div className="font-weight-bold py-3" style={{ color: "#FFF", fontSize: 18 }}>{awards[awaindex].name}</div>
          <div className="py-3" style={{ color: "#FFF", fontSize: 14 }}>{cname}</div>
          {props.hall && <Link to={{
            pathname: "/hall-" + props.hall.name.substr(5).replace(' & ', '-'),
            state: { exid: awards[awaindex].exhibitor_id }
          }} style={{ color: "#FFF", fontSize: 10 }}>Visit Booth</Link>}
        </Modal.Body>
      </Modal>
    </div>
  )
}