import React from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Placeholder from '../images/placeholder.png';

function Productcards(props) {
  const [show, setShow] = React.useState(false);
  const [imgindex, setImgindex] = React.useState(0);
  const [vshow, vsetShow] = React.useState(false);
  const [vidindex, setVidindex] = React.useState(0);

  /* var products = props.products;
  
  products.forEach(function(item,i){
    if(item.videos.length > 0){
      products.splice(i, 1);
      products.unshift(item);
    }
  }); */

  const listItems = (
    <>
      {!!props.products?.length ? (
        props.products?.map((tile, index) => (
          <div className="border-0 prodcard-2 my-2 position-relative" key={index}>
            {tile.vr_url ? (
              <button className="img-btn" onClick={() => window.open(tile.vr_url, "_blank")}>
                <img className="prodcard-img" src={tile.images} alt={tile.name} style={{ width: "100%", borderRadius: "5px 5px 0 0" }} onError={(e) => { e.target.onerror = null; e.target.src = Placeholder }}></img>
              </button>
            ) : (tile.videos.length > 0 && tile.additional_url) ? (
              <button className="img-btn" onClick={() => window.open(tile.additional_url, "_blank")}>
                <iframe title={index} className="prodcard-img" style={{ pointerEvents: 'none', height: '100%' }}
                  src={tile.videos + "?autoplay=0&showinfo=0&controls=0&autohide=1"} frameBorder="0" allowFullScreen>
                </iframe>
              </button>
            ) : (tile.videos.length > 0 && !tile.additional_url) ? (
              <button className="img-btn" onClick={() => { vsetShow(true); setVidindex(index) }}>
                <iframe title={index} className="prodcard-img" style={{ height: '100%', pointerEvents: 'none' }}
                  src={tile.videos + "?autoplay=0&showinfo=0&controls=0&autohide=1"} frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen>
                </iframe>
              </button>
            ) : (tile.images.length > 0 && tile.additional_url) ? (
              <button className="img-btn" onClick={() => window.open(tile.additional_url, "_blank")}>
                <img className="prodcard-img" src={tile.images} alt={tile.name}></img>
              </button>
            ) : (tile.images.length > 0 && !tile.additional_url) ? (
              <button className="img-btn" onClick={() => { setShow(true); setImgindex(index) }}>
                <img className="prodcard-img" src={tile.images} alt={tile.name}></img>
              </button>
            ) : (
              <button className="img-btn" onClick={() => { setShow(true); setImgindex(index) }}>
                <img className="prodcard-img" src={Placeholder} alt={tile.name} style={{ width: "100%", borderRadius: "5px 5px 0 0" }}></img>
              </button>
            )}
            <div className="card-body border-white d-flex flex-column" style={{ padding: 10 }}>
              <div className="prodcard-title">
                {tile.vr_url ? tile.exhibitor_with_hall?.company_name : tile.name}
              </div>
              <div className="prodcard-subtitle">
                {tile.vr_url ? 'Hall ' + tile.exhibitor_with_hall?.booth_number : tile.exhibitor_with_hall?.company_name}
              </div>
              {(tile.exhibitor_with_hall?.hall && tile.vr_url) && (
                <div className="mt-auto">
                  <Link
                    to={{
                      pathname: "/hall-" + tile.exhibitor_with_hall?.hall.name.substr(5).replace(' & ', '-'),
                      state: { exid: tile.exhibitor_id }
                    }}
                  >
                    <div className="visit-booth-btn-3">
                      <span>Locate Booth</span>
                      <span className="ml-auto">
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.497 5.33333C12.3142 5.33333 12.1389 5.40357 12.0097 5.5286C11.8804 5.65362 11.8078 5.82319 11.8078 6V10C11.8078 10.1768 11.7352 10.3464 11.6059 10.4714C11.4767 10.5964 11.3013 10.6667 11.1185 10.6667H2.84754C2.66474 10.6667 2.48942 10.5964 2.36016 10.4714C2.2309 10.3464 2.15829 10.1768 2.15829 10V2C2.15829 1.82319 2.2309 1.65362 2.36016 1.5286C2.48942 1.40357 2.66474 1.33333 2.84754 1.33333H6.98304C7.16584 1.33333 7.34115 1.2631 7.47041 1.13807C7.59967 1.01305 7.67229 0.843478 7.67229 0.666667C7.67229 0.489856 7.59967 0.320286 7.47041 0.195262C7.34115 0.0702379 7.16584 0 6.98304 0H2.84754C2.29913 0 1.77319 0.210714 1.38542 0.585787C0.997637 0.960859 0.779785 1.46957 0.779785 2V10C0.779785 10.5304 0.997637 11.0391 1.38542 11.4142C1.77319 11.7893 2.29913 12 2.84754 12H11.1185C11.6669 12 12.1929 11.7893 12.5807 11.4142C12.9684 11.0391 13.1863 10.5304 13.1863 10V6C13.1863 5.82319 13.1137 5.65362 12.9844 5.5286C12.8552 5.40357 12.6798 5.33333 12.497 5.33333Z" fill="#363636" />
                          <path d="M9.74033 1.33334H10.8293L6.49398 5.52001C6.42938 5.58199 6.3781 5.65572 6.34311 5.73696C6.30811 5.8182 6.2901 5.90534 6.2901 5.99335C6.2901 6.08136 6.30811 6.16849 6.34311 6.24973C6.3781 6.33097 6.42938 6.40471 6.49398 6.46668C6.55805 6.52917 6.63428 6.57877 6.71827 6.61261C6.80226 6.64646 6.89235 6.66388 6.98334 6.66388C7.07433 6.66388 7.16442 6.64646 7.24841 6.61261C7.3324 6.57877 7.40863 6.52917 7.47271 6.46668L11.8081 2.28001V3.33334C11.8081 3.51015 11.8807 3.67972 12.0099 3.80475C12.1392 3.92977 12.3145 4.00001 12.4973 4.00001C12.6801 4.00001 12.8554 3.92977 12.9847 3.80475C13.1139 3.67972 13.1866 3.51015 13.1866 3.33334V0.666668C13.1866 0.489857 13.1139 0.320287 12.9847 0.195263C12.8554 0.0702381 12.6801 0 12.4973 0H9.74033C9.55753 0 9.38222 0.0702381 9.25296 0.195263C9.1237 0.320287 9.05108 0.489857 9.05108 0.666668C9.05108 0.84348 9.1237 1.01305 9.25296 1.13807C9.38222 1.2631 9.55753 1.33334 9.74033 1.33334Z" fill="#363636" />
                        </svg>
                      </span>
                    </div>
                  </Link>
                </div>
              )}
              {(tile.exhibitor_with_hall?.hall && !tile.vr_url) && (
                <div className="mt-auto">
                  <Link
                    to={{
                      pathname: "/hall-" + tile.exhibitor_with_hall?.hall?.name?.substr(5)?.replace(' & ', '-'),
                      state: { exid: tile.exhibitor_id }
                    }}
                    className="visit-booth-btn-2"
                  >
                    Locate Booth
                  </Link>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="text-center w-100" style={{ paddingTop: 34 }}>No results found.</div>
      )}
    </>
  );

  const product = props.products?.[imgindex] || {};
  const vproduct = props.products?.[vidindex] || {};

  return (
    <div className="p-3 mb-5 slide-btm-div" style={{ maxWidth: 950, margin: "auto", textAlign: "left" }}>
      <div className="d-flex flex-wrap justcon-center">
        {listItems}
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={product.images} alt={product.name || "efe-product"} style={{ width: "100%", borderRadius: 5 }} />
          <div className="font-weight-bold py-3" style={{ color: "#FFF", fontSize: 18 }}>{product.exhibitor_with_hall?.company_name || "-"}</div>
          {!!product.exhibitor_with_hall?.hall && <Link to={{
            pathname: "/hall-" + product.exhibitor_with_hall?.hall?.name?.substr(5)?.replace(' & ', '-'),
            state: { exid: product.exhibitor_id }
          }} style={{ color: "#FFF", fontSize: 10 }}>Visit Booth</Link>}
        </Modal.Body>
      </Modal>

      <Modal
        show={vshow}
        onHide={() => vsetShow(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe title={vproduct.name || "efe-product"} className="desktop-modal-vid" style={{ width: "100%", borderRadius: 5, minHeight: 300 }}
            src={vproduct.videos} frameBorder="0" allowFullScreen>
          </iframe>
          <div className="font-weight-bold py-3" style={{ color: "#FFF", fontSize: 18 }}>{vproduct.exhibitor_with_hall?.company_name || "-"}</div>
          {!!product.exhibitor_with_hall?.hall && <Link to={{
            pathname: "/hall-" + vproduct.exhibitor_with_hall?.hall?.name?.substr(5).replace(' & ', '-'),
            state: { exid: vproduct.exhibitor_id }
          }} style={{ color: "#FFF", fontSize: 10 }}>Visit Booth</Link>}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Productcards