import React from "react";
import { Link } from "react-router-dom";
import '../App.css';
import efelogo from '../images/EFE2023.png';
import wow360 from '../images/g18.png';
/* import Searchbar from '../components/SearchBar'; */
import ProdFiltertab from '../components/ProductFiltertab';
/* import SearchIcon from '@material-ui/icons/Search'; */
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import { makeStyles } from '@material-ui/core/styles';

const navStyles = makeStyles({
    root: {
        color: "#6A6A6A",
        "padding-top": 0,
        "&$selected": {
            color: "#EC2034",
            padding: 0,
        }
    },
    selected: {},
    wrapper: {
        width: "50%",
        height: "100%",
    }
});

const Products = () => {

    const navclasses = navStyles();

    return (
        <div className="App">
            <div className="maindiv">
                <div className="header">
                    <Link to="/"><img src={efelogo} alt="EFElogo" className="efelogo" /></Link>
                </div>

                <ProdFiltertab />

            </div>

            <footer>
                <BottomNavigation value={'products'} className="autofit" showLabels={true}>
                    <BottomNavigationAction classes={navclasses} label="Home" value="home" icon={<HomeRoundedIcon />} component={Link} to="/" />
                    <BottomNavigationAction classes={navclasses} label="Exhibitors" value="exhibitors" icon={<StorefrontRoundedIcon />} component={Link} to="/exhibitors" />
                    <BottomNavigationAction classes={navclasses} label="Products" value="products" icon={<LocalMallRoundedIcon />} component={Link} to="/products" />
                    <BottomNavigationAction classes={navclasses} value="highlights" icon={<img src={wow360} alt="WOW 360" height="35" />} component={Link} to="/wow360" />
                </BottomNavigation>
            </footer>
        </div>
    )
}

export default Products;