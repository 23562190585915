import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Slidingpane from '../components/SlidingPane';
import Placeholder from '../images/placeholder.png';
import Eggthemighty from './Egglogo';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "auto",
    padding: "40px 0 100px 0 ",
    maxWidth: "900px",
  },
  image: {
    width: 80,
    height: 80,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.1,
    color: "#575757",
    letterSpacing: '0.03em',
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  txt: {
    paddingTop: "5px",
    fontSize: "10px"

  },
  booth: {
    paddingTop: "0px",
    fontSize: "20px",
    fontWeight: "bold"
  },
  btn: {
    width: 80,
    fontSize: 10,
    margin: '10px 15px 5px 0',
    borderRadius: 3,
    background: 'rgba(236, 32, 52, 0.2)',
    border: 'none',
    padding: 5,
    color: '#EC2034',
    fontWeight: 600,
  },
}));

function Exhibitorcards(props) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.exlist.map(tile => (
        <Paper className="exhibitor-paper" key={tile.id}>
          <div className="d-flex align-self-center w-100">
            <div className="align-self-center">
              {tile.company_logo && <img className="slide-logo px-3" src={tile.company_logo} alt="logo" />}
              {!tile.company_logo && <img className="slide-logo px-3" src={Placeholder} alt="logo" />}
            </div>
            <div className="d-flex flex-column align-self-center px-4" style={{ flex: 'auto' }}>
              <div className="d-flex">
                <div className="mr-2">
                  <div className={classes.title}>{tile.company_name}</div>
                  <div className={classes.txt}>HALL {tile.booth_number}</div>
                </div>
                {tile.is_vr === 1 && <div className="vr-btn"><Eggthemighty /></div>}
              </div>
              <div className="d-flex flex-row">
                {tile.hall && <Link to={{
                  pathname: "/hall-" + tile.hall.name.substr(5).replace(' & ', '-'),
                  state: { exid: tile.id }
                }} className="visit-booth-btn mr-2">Locate Booth</Link>}
                <Slidingpane exhibitor={tile.id} css={classes.btn} hall={tile.hall} shortname={tile.short_name} />
              </div>
            </div>
          </div>
        </Paper>
      ))}
    </div>

  );
}

export default Exhibitorcards;