import React from 'react';
import axios from 'axios';
import AwesomeSwiper from 'react-awesome-swiper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
if (vw > 1000) {
  vw = 1000;
}

var slideno = 1;
var space = 10;

if (vw < 968) {
  slideno = vw / 115;
  space = 10;
} else {
  slideno = vw / 170;
  space = 15;
}

const config = {
  loop: true,
  slidesPerView: slideno,
  spaceBetween: space,
  mousewheel: true,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  },
  // Disable preloading of all images
  preloadImages: false,
  // Enable lazy loading
  lazy: true,
  speed: 500,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    bulletElement: 'li',
    hideOnClick: true,
    clickable: true,
  },
  on: {
    slideChange: function () {
      //console.log(this.activeIndex);
    },
  },
};

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

class Mycarousel extends React.Component {

  constructor(props) {
    super(props);
    this.scroller = React.createRef();
    this.state = {
      banner: [],
      error: false,
      isloaded: false,
    };
  };


  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_HOST}/api/banners`)
      .then(res => {
        var response = res.data;
        //response.splice(0,0,response.pop());
        shuffle(response);
        this.setState({
          banner: response,
          isloaded: true,
        });
        //console.log(response)
      })
      .catch(error => {
        this.setState({ error: true, isloaded: true, errordata: error });
      })
  }

  scrollRight = () => {
    for (let i = 1; i <= 30; i++) {
      setTimeout(() => (this.scroller.current.scrollLeft += i), i * 5);
    }
  }

  scrollLeft = () => {
    for (let i = 1; i <= 30; i++) {
      setTimeout(() => (this.scroller.current.scrollLeft -= i), i * 5);
    }
  }

  render() {

    if (this.state.isloaded) {
      return (
        <div className="hero-wrapper">
          <AwesomeSwiper ref={ref => (this.swiperRef = ref)} config={config}>
            <div className="swiper-wrapper">
              {this.state.banner.map((tile, index) => (
                <a key={index} className="swiper-slide" href={tile.link} target="blank" rel="noopener noreferrer">
                  <img className="swiper-image" src={tile.image} alt={"slide " + tile.id} />
                </a>
              ))}
            </div>
            {slideno < this.state.banner.length && <div className="swiper-button-prev"><ChevronLeftIcon style={{ color: "#373737" }} /></div>}
            {slideno < this.state.banner.length && <div className="swiper-button-next"><ChevronRightIcon style={{ color: "#373737" }} /></div>}
            <div className="swiper-pagination" style={{ display: 'none' }}></div>
            {/* <div className="slide-wrapper" style={{paddingBottom: 20}}>
              <div ref={this.scroller} className="d-flex flex-nowrap slidecontain">
                {this.state.banner.map((tile, index) => (
                  <a key={index} className="slidebox" href={tile.link} target="blank" rel="noopener noreferrer">
                    <img className="slideimage" src={tile.image} alt={"slide "+tile.id} />
                  </a>
                ))}
              </div>
              <button className="left-btn" onClick={this.scrollLeft}><ChevronLeftIcon style={{ color: "#373737" }}/></button>
              <button className="right-btn" onClick={this.scrollRight}><ChevronRightIcon style={{ color: "#373737" }}/></button>
            </div> */}
          </AwesomeSwiper>
        </div>
      )
    } else {
      return (
        <div style={{ padding: '20px 0' }}>
          <CircularProgress />
        </div>
      )
    }
  };
}

export default Mycarousel;
