import React from 'react';
import Placeholder from '../images/placeholder.png';

function Productcategories(props) {

    var listItems = props.categories.map(tile =>
        <div className="border-0 cate-card position-relative" key={tile.value}>
            <button className="img-btn" onClick={props.handleclick} title={tile.value}>
                {tile.image && <img title={tile.value} className="img-prod" src={tile.image} alt={tile.name} style={{ width: "100%", borderRadius: "5px 5px 0 0" }} />}
                {!tile.image && <img title={tile.value} className="img-prod" src={Placeholder} alt={tile.name} style={{ width: "100%", borderRadius: "5px 5px 0 0" }} />}
            </button>
            <div className="cate-caption">{tile.label}</div>
        </div>
    );

    return (
        <div className="p-3 mb-5 slide-btm-div" style={{ maxWidth: 950, margin: "auto", textAlign: "center" }}>
            <div className="d-flex flex-wrap justcon-center">
                {listItems}
            </div>
        </div>
    );
}

export default Productcategories