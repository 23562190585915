import React from "react";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Modal from 'react-bootstrap/Modal';


class Hallwithbooths extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hallid: '',
			hallname: '',
			floorplan: null,
			booths: [],
			isloaded: false,
			error: false,
			errordata: null,
			show: false,
			showindex: 0,
		};
	};

	onClick = (event) => {
		event.preventDefault();
		//console.log(event.target.key)
	}

	onPopup = (i) => {
		this.setState({
			show: true,
			showindex: i,
		})
	}

	onPopclose = () => {
		this.setState({
			show: false,
		})
	}

	componentDidMount() {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/halls`)
			.then(res => {
				const resd = res.data;
				const result = resd.filter(obj => {
					return obj.name === this.props.hall
				})
				const hallid = "" + result[0].id
				axios.get(`${process.env.REACT_APP_API_HOST}/api/halls/` + hallid)
					.then(res => {
						const response = res.data.hall;
						//console.log(response)
						this.setState({
							hallid: response.id,
							hallname: response.name,
							booths: response.booths,
							floorplan: response.floorplan,
							isloaded: true,
						});
					})
					.catch(error => {
						this.setState({ error: true, isloaded: true, errordata: error });
					})
			})
			.catch(error => {
				this.setState({ error: true, isloaded: true, errordata: error });
			})
	}

	render() {

		const { props: { handleClick }, state: { booths, isloaded, error, show, showindex }, onPopclose, onPopup } = this;
		let exbooths;

		if (isloaded && !error) {
			if (booths.length && this.state.floorplan) {
				exbooths = (
					<div>
						<img src={this.state.floorplan} alt={this.state.hallname} width="100%" />
						{booths.map((ex, index) => {

							var pointer = {}
							const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
							var style = {
								position: "absolute",
								width: ex.width + "%",
								height: ex.height + "%",
								left: ex.left + "%",
								top: ex.top + "%",
								transform: ex.rotation,
								flexDirection: 'column',
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								/* boxShadow: "0px 0px 2px 1px #FF9595",
								background: "#FF9595", */
								boxShadow: "none",
								background: "none",
								border: "none",
								transition: "all 0.5s"
							}

							pointer = {
								position: 'absolute',
								transition: 'all 0.5s',
								display: 'block',
								color: 'rgba(175,175,175,0)',
								cursor: "pointer",
								bottom: '55%',
								left: '50%',
								top: '50%',
								borderRadius: '50%',
								pointerEvents: 'none',
								WebkitTransform: 'translate(-50%, -50%)',
								MozTransform: 'translate(-50%, -50%)',
								transform: 'translate(-50%, -50%)',
							}

							if (this.props.selected === ex.exhibitor_id && ex.exhibitor_id !== null) {
								pointer.color = 'red';
								if (vw > 800) {
									pointer.fontSize = '12px';
								} else {
									pointer.fontSize = '4px';
								}
								pointer.background = 'rgba(255,255,255,0.5)';
								pointer.animation = 'glow 1s infinite alternate';
							} else {
								if (vw > 800) {
									pointer.fontSize = '2px';
								} else {
									pointer.fontSize = '2px';
								}
								pointer.background = 'rgba(255,255,255,0)';
							}

							if (ex.exhibitor) {
								return (
									<button onClick={() => handleClick(ex.exhibitor_id)} style={style} key={ex.id} id={ex.exhibitor.short_name} className="floor-invi-btn">
										<FiberManualRecordIcon style={pointer} className="pinicon" name={ex.exhibitor_id} />
										{/* <div name={ex.exhibitor_id} onClick={handleClick} className="exh-name-floor">{ex.exhibitor.short_name}</div> */}
										{/*ex.exhibitor_id === 542 && <div name={ex.exhibitor_id} onClick={() => handleClick(ex.exhibitor_id)} className="exh-name-floor">VACANT</div>*/}
									</button>
								);
							} else {
								return (
									<button style={style} key={ex.id} onClick={() => onPopup(index)}></button>
								)
							}
						})}
						<Modal
							show={show}
							onHide={onPopclose}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title"
							centered={true}
						>
							<Modal.Header closeButton></Modal.Header>
							<Modal.Body>
								<img src={booths[showindex].image} alt={booths[showindex].label} style={{ width: "100%", borderRadius: 5 }} />
								<div className="font-weight-bold py-3" style={{ color: "#FFF", fontSize: 18 }}>{booths[showindex].label}</div>
							</Modal.Body>
						</Modal>
					</div>
				);
			} else if (this.state.floorplan) {
				exbooths = (
					<img src={this.state.floorplan} alt={this.state.hallname} width="100%" />
				)
			} else {
				exbooths = (
					<div>Failed Loading</div>
				)
			}
		}

		return (
			<div style={{ width: "100%", position: "relative" }}>
				{!this.state.isloaded && !this.state.error && <div className="mt-5"><CircularProgress /></div>}

				{this.state.isloaded && !this.state.error &&
					exbooths
				}

				{this.state.isloaded && this.state.error &&
					<div style={{ margin: "auto", paddingTop: 50 }}>No results found</div>
				}
			</div>
		)
	}
};

export default Hallwithbooths
